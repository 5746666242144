/* ./src/index.css */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@tailwind base;
@tailwind components;

@import './utility-patterns.scss';
@import './range-slider.scss';
@import './toggle-switch.scss';
@import './flatpickr.scss';
@import './theme.scss';

@tailwind utilities;